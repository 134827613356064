import React, { Component } from "react";
import Turnstile from "react-turnstile";
import _ from 'lodash';

class ContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
      loading: false,
      submitStatus: null,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    fetch("/api/forms/submit", {
      method: "POST",
      body: new FormData(e.target),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        const { success } = res;

        if (success) {
          console.log(e);
          // e.target.reset();
          this.setState({
            submitStatus: {
              message: "Thank you for your message.",
              type: "success",
            },
          });
        } else {
          this.setState({
            submitStatus: {
              message: "Something went wrong. Please try again later.",
              type: "danger",
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          submitStatus: {
            message: "Something went wrong.",
            type: "danger",
          },
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { disable, loading, submitStatus } = this.state;
    const section = _.get(this.props, 'section', null);

    return (
      <section className="section contact">
        <div className="container container--lg mt-5">
          <div className="flex flex--col-2">
              <div className="cell">
                {_.get(section, 'title_first', null) && (
                  <h3 className="section__title align-left">{_.get(section, 'title_first', null)}</h3>
                )}
                {_.get(section, 'sub_first', null) && (
                  <span className="sub align-left">{_.get(section, 'sub_first', null)}</span>
                )}
                {_.get(section, 'title_second', null) && (
                  <h3 className="section__title align-left mt-3">{_.get(section, 'title_second', null)}</h3>
                )}
                <ul className="info-list mt-3">
                {_.get(section, 'address', null) && (
                  <>
                    <li className="adress">
                      <p className="align-left mb-1">{_.get(section, 'title_address_first', null)}</p>
                      <a href="https://goo.gl/maps/z8Z12oGDnH1fRGmr9" target="_blank" rel="noreferrer">{_.get(section, 'address', null)}</a>
                      <p>
                        <a href={"tel:" + (_.get(section, 'phone', null))}>{_.get(section, 'phone', null)} {_.get(section, 'hours', null)}</a>
                      </p>
                    </li>
                    <li className="adress">
                      <p className="align-left mb-1">{_.get(section, 'title_address_second', null)}</p>
                      <a href="https://goo.gl/maps/1K97vSprZU3V95fq5" target="_blank" rel="noreferrer">{_.get(section, 'address2', null)}</a>
                      <p>
                        <a href={"tel:" + (_.get(section, 'phone2', null))}>{_.get(section, 'phone2', null)} {_.get(section, 'hours2', null)}</a>
                      </p>
                    </li>
                    <li className="adress">
                      <p className="align-left mb-1">{_.get(section, 'title_address_third', null)}</p>
                      {/* <a href="#" target="_blank" rel="noreferrer">{_.get(section, 'address3', null)}</a> */}
                      <p className="mb-0">{_.get(section, 'address3', null)}</p>
                      <p className="phone mb-0">
                        <a href={"tel:" + (_.get(section, 'phone3', null))}>{_.get(section, 'phone3', null)} {_.get(section, 'hours3', null)}</a>
                      </p>
                      <p className="phone">
                        <a href={"tel:" + (_.get(section, 'mobile', null))}>{_.get(section, 'mobile', null)}</a>
                      </p>
                    </li>
                  </>
                  
                  )}
                    {/* {_.get(section, 'phone', null) && (
                  <li className="phone">
                    <a href={"tel:" + (_.get(section, 'phone', null))} target="_blank" rel="noreferrer">{_.get(section, 'phone', null)}
                    </a>
                  </li>
                  )} */}
                    {_.get(section, 'email', null) && (
                  <li className="email">
                  <a href={"mailto:" + (_.get(section, 'email', null))} target="_blank" rel="noreferrer">{_.get(section, 'email', null)}</a>
                    </li>
                  )}
                </ul>
              </div>

            <div className="cell">
              <form onSubmit={this.onSubmit}>
                <input type="hidden" name="Form" value="Contact" />

                <div className="mb-1">
                  <input type="text" className="form-control" name="Name" id="contact.name" aria-labelledby="name-label" placeholder={_.get(section, 'name_label', null)} required/>
                </div>

                <div className="mb-1">
                  <input type="email" className="form-control" name="Email" id="contact.email" aria-labelledby="email-label" placeholder={_.get(section, 'email_label', null)} required />
                </div>

                <div className="mb-1">
                  <input type="text" className="form-control" name="Subject" id="contact.subject" aria-labelledby="subject-label" placeholder={_.get(section, 'subject_label', null)} required/>
                </div>

                <div className="mb-1">
                  <textarea className="form-control" name="Message" id="contact.message" aria-labelledby="message-label" rows={3} placeholder={_.get(section, 'message_label', null)}></textarea>
                </div>

                <Turnstile
                  sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
                  theme="light"
                  onError={() => this.setState({disable: true})}
                  onExpire={() => this.setState({disable: true})}
                  onVerify={() => this.setState({disable: false})}
                  className="mb-3"
                />

                {submitStatus && (
                <div className={`alert alert-${submitStatus.type} my-3 text-center`} role="alert">
                  {submitStatus.message}
                </div>
              )}

                <button type="submit" className="btn btn-primary my-3" disabled={disable || loading}>
                  {_.get(section, 'submit_text', null)}
                  {loading && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                </button>
              </form>
              
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactSection;
