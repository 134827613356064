import React from 'react';
import _ from 'lodash';
import NewsletterSection from './NewsletterSection'
import Action from './Action';
import {htmlToReact, Link, withPrefix} from '../utils';

export default class Footer extends React.Component {

       
    render() {
      if (typeof window !== 'undefined') {
        var local = JSON.parse(localStorage.getItem('locale')) || "en";
        }
        else{
            var local = 'en';
        }
        if (local==='el' ||  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.contact_title', null)) == null ){
            var contact =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.contact_title', null))
            var policies =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.policies_title', null))
            var newsletter =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.newsletter_text', null))
            var address_title =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address_title', null))
            var address = htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address', null))
            var address2_title =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address2_title', null))
            var address2 = htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address2', null))
            var address3_title =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address3_title', null))
            var address3 =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address3', null))

        }
        else if(local ==='en'){
            var contact = htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.contact_title_en', null))
            var policies =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.policies_title_en', null))
            var newsletter = htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.newsletter_text_en', null))
            var address_title =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address_title_en', null))
            var address = htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address_en', null))
            var address2_title =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address2_title_en', null))
            var address2 = htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address2_en', null))
            var address3_title =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address3_title_en', null))
            var address3 =  htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address3_en', null))
          
        }
        return (
            <footer className="site-footer">
              <div className="container container--lg">
              <div className="container container--lg footer-content flex--col-5">
                <div className="cell logo-cell">
              {_.get(this.props, 'pageContext.site.siteMetadata.header.logo', null) ? (
                  <Link className="footer__logo" to={withPrefix('/')}>
                    <img className="logo"
                      src={withPrefix(_.get(this.props, 'pageContext.site.siteMetadata.footer.footer_logo', null))} alt={_.get(this.props, 'pageContext.site.siteMetadata.header.logo_alt', null)} />
                     </Link>
                  ) : 
                  <Link className="h4 navbar__title" to={withPrefix('/')}>{_.get(this.props, 'pageContext.site.siteMetadata.header.title', null)}</Link>
                  }
                  </div>
                  
                  {/* Footer nav menu */}

                {(_.get(this.props, 'pageContext.site.siteMetadata.footer.has_nav', null) || _.get(this.props, 'pageContext.site.siteMetadata.footer.has_social', null)) && (
                <div className="cell" >
                <div className="site-footer__nav">
                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.has_nav', null) && (
                  <ul className="site-footer__menu menu">
                    {_.map(_.get(this.props, 'pageContext.site.siteMetadata.footer.nav_links', null), (action, action_idx) => (
                    <li key={action_idx}>
                      <Action {...this.props} action={action} />
                    </li>
                    ))}
                  </ul>
                  )}
                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.has_social', null) && (
                  <ul className="site-footer__social menu">
                    {_.map(_.get(this.props, 'pageContext.site.siteMetadata.footer.social_links', null), (action, action_idx) => (
                    <li key={action_idx}>
                      <a href={action.url} target='_blank'>
                            <img src={action.icon} alt={action.label} style={{ height: "20px" }} />
                      </a>
                    </li>
                     
                    ))}
                  </ul>
                  )}
                </div>
                </div>
                )}

                {/* Footer nav menu policies */}
                {(_.get(this.props, 'pageContext.site.siteMetadata.footer.has_nav', null) || _.get(this.props, 'pageContext.site.siteMetadata.footer.has_social', null)) && (
                <div className="cell" >
                <div className="site-footer__nav">
                  <div className="menu-title">{policies}</div>
                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.has_nav', null) && (
                  <ul className="site-footer__menu menu">
                    {_.map(_.get(this.props, 'pageContext.site.siteMetadata.footer.nav_links_policy', null), (action, action_idx) => (
                    <li key={action_idx}>
                      <Action {...this.props} action={action} />
                    </li>
                    ))}
                  </ul>
                  )}
                </div>
                </div>
                )}
              {/* Contact menu */}
                <div className="cell">
                  <div className="site-footer__nav">
                    <div className="menu-title">{contact}</div>
                    <ul className="site-footer__menu menu">
                      
                      <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          <p>{address_title}</p>
                          // <a href={"tel:" + htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}</a>
                        )}
                      </li>
                       <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          // <p>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address', null))}</p>
                          <a href="https://goo.gl/maps/uHbm8vhbpxM5T6bA8" target="_blank" rel="noreferrer">{ address }</a>
                        )}
                      </li>
                      <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          // <p>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}</p>
                          <a href={"tel:" + htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}</a>
                        )}
                      </li>
                      <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          <p>{address2_title}</p>
                          // <a href={"tel:" + htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}</a>
                        )}
                      </li>
                       <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          // <p>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address', null))}</p>
                          <a href="https://goo.gl/maps/uyXLnSmUsYVK9FyH8" target="_blank" rel="noreferrer">{ address2 }</a>
                        )}
                      </li>

                      <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          <p>{address3_title}</p>
                          // <a href={"tel:" + htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}</a>
                        )}
                      </li>
                       <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          // <p>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.address', null))}</p>
                          <a href="https://goo.gl/maps/C9vjkLyf2hnm6bpQ6" target="_blank" rel="noreferrer">{ address3 }</a>
                        )}
                      </li>
                      <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          // <p>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}</p>
                          <a href={"tel:" + htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone3', null))}>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone3', null))}</a>
                        )}
                      </li>
                      <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          // <p>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone', null))}</p>
                          <a href={"tel:" + htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone3_mob', null))}>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.telephone3_mob', null))}</a>
                        )}
                      </li>


                       <li>
                        {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                          <a href={"mailto:" + htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.email', null))}>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.email', null))}</a>
                        )}
                      </li>
                    </ul>
                  </div>
                  </div>

                  {/* Newsletter */}

                  {/* <div className="cell">
                    <div>
                    <div className="menu-title">Newsletter</div>
                    {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                    <span>{newsletter}</span>
                     
                  )}
                  <NewsletterSection {...this.props} />
                    </div>
                  </div> */}
                 </div>

                  {/* Footer Copyrights */}

                <div className="container container--lg footer-copyrights">
                <div className="site-footer__copyright">
                  {_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null) && (
                    <span>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.content', null))}</span>
                     
                  )}
                   {new Date().getFullYear()}.
                   {_.get(this.props, 'pageContext.site.siteMetadata.footer.developed_by', null) && (
                    <span>{htmlToReact(_.get(this.props, 'pageContext.site.siteMetadata.footer.developed_by', null))}</span>
                     
                  )}
                 
                  {_.map(_.get(this.props, 'pageContext.site.siteMetadata.footer.links', null), (action, action_idx) => (
                    <Action key={action_idx} {...this.props} action={action} />
                  ))}
                </div>
                </div>
             
              </div>
            </footer>
        );
    }
}
